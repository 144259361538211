<template>
  <div class="write-off-record">
    <el-row>
      <el-col :span="10">
        <el-row>
          <el-col :span="10">
            <el-date-picker
              :clearable="true"
              v-model="formData.stime"
              type="date"
              placeholder="核销时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="2" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="10">
            <el-date-picker
              :clearable="true"
              v-model="formData.etime"
              type="date"
              placeholder="核销时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="9"> </el-col>
      <el-col :span="5" class="text-right">
        <el-tooltip class="item" effect="dark" content="导出" placement="left">
          <span class="border-icon" @click="handleExport">
            <i class="iconfont icon-a-lujing207"></i
          ></span>
        </el-tooltip>

        <el-tooltip class="item" effect="dark" content="打印" placement="left">
          <span class="border-icon mr-10" @click="handlePrint">
            <i class="iconfont icon-a-lujing206"></i
          ></span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table
      class="ar-table"
      v-loading="loading"
      :data="tableData"
      tooltip-effect="light"
      stripe
      height="360"
      style="width: 100%"
    >
      <el-table-column property="sn" label="核销单号" width="200">
        <template #default="scope">
          <span class="text-operation" @click="handleDetail(scope.row)">
            {{ scope.row.sn }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="time" label="核销日期">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.time }}
          </span></template
        >
      </el-table-column>

      <el-table-column property="money" label="核销金额">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.money }}
          </span>
        </template>
      </el-table-column>
      <el-table-column property="arc_check_way_txt" label="核销方式">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_check_way_txt }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="create_name" label="核销人">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.create_name }}
          </span></template
        >
      </el-table-column>
      <el-table-column property="arc_ctime" label="核销时间" width="160">
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_ctime }}
          </span></template
        >
      </el-table-column>
      <el-table-column
        property="arc_remark"
        label="核销备注"
        width="200"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          <span class="text-grey">
            {{ scope.row.arc_remark }}
          </span></template
        >
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <el-popconfirm
            title="确定要撤销该订单吗?"
            @confirm="handleWriteOff(scope.row)"
          >
            <template #reference>
              <el-button class="theme-font-btn table-btn">撤销</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        v-model:currentPage="listQuery.page"
        v-model:page-size="listQuery.limit"
        :page-sizes="[20, 50, 100, 200, 500]"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="listQuery.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentPageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { VipApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { exportExcel, print } from "@/utils/common.js";
import { useStore } from "vuex";
import dayjs from "dayjs";
export default {
  props: {
    detailId: {
      type: Number,
      default: 0,
    },
  },
  emits: ["viewDetail"],
  setup(props, { emit }) {
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const listQuery = ref({
      page: 1,
      limit: 20,
      total: 0,
    });
    const formData = ref({
      stime: "",
      etime: "",
    });
    function changeStime(data) {
      if (
        formData.value["etime"] &&
        dayjs(formData.value["etime"]).isBefore(dayjs(data))
      ) {
        //如果结束时间有值 且 在开始时间之前
        formData.value["stime"] = formData.value["etime"];
        formData.value["etime"] = data;
      } else {
        formData.value["stime"] = data;
      }
      fetchData();
    }
    function changeEtime(data) {
      if (
        formData.value["stime"] &&
        dayjs(formData.value["stime"]).isAfter(dayjs(data))
      ) {
        formData.value["etime"] = formData.value["stime"];
        formData.value["stime"] = data;
      } else {
        formData.value["etime"] = data;
      }
      fetchData();
    }
    const tableData = ref([]);
    onMounted(() => {
      fetchData();
    });
    const loading = ref(false);
    function fetchData() {
      const lastParmas = {
        ar_id: props.detailId,
        req_type: 2,
        page: listQuery.value.page,
        limit: listQuery.value.limit,
        start_time: formData.value["stime"]
          ? dayjs(formData.value["stime"]).format("YYYY-MM-DD")
          : "",
        end_time: formData.value["etime"]
          ? dayjs(formData.value["etime"]).format("YYYY-MM-DD")
          : "",
      };
      loading.value = true;
      VipApi.accountDetail(lastParmas).then((res) => {
        loading.value = false;
        if (res.Code === 200) {
          tableData.value = res.Data.list;
          listQuery.value.total = res.Data.count;
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    }
    function handleSizeChange(val) {
      listQuery.value.limit = val;
      fetchData();
    }
    function handleCurrentPageChange(p) {
      listQuery.value.page = p;
      fetchData();
    }
    function handleWriteOff(row) {
      if (authData.value.indexOf("n_FeR2uOU48lPni6JFNPsYVCQGhjl0") != -1) {
        const data = {
          arc_id: row.id,
        };
        VipApi.revokeCheckOrder(data).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("撤销成功！");
            fetchData();
          } else {
            let msg = res.Message ? res.Message : "撤销失败！";
            ElMessage.error(msg);
          }
        });
      } else {
        ElMessage.warning("您没有此操作权限！");
      }
    }
    function handleDetail(row) {
      emit("viewDetail", row.sn);
    }
    const propertiesConfig = ref([
      { field: "sn", displayName: "核销单号" },
      { field: "time", displayName: "核销日期" },
      { field: "money", displayName: "核销金额" },
      { field: "arc_check_way_txt", displayName: "核销方式" },
      { field: "create_name", displayName: "核销人" },
      { field: "arc_ctime", displayName: "核销时间" },
      { field: "arc_remark", displayName: "核销备注" },
    ]);
    function handlePrint() {
      print("核销记录", tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel("核销记录", tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      tableData,
      loading,
      handleSizeChange,
      handleCurrentPageChange,
      listQuery,
      handleExport,
      handlePrint,
      formData,
      changeStime,
      changeEtime,
      handleWriteOff,
      handleDetail,
      authData,
    };
  },
};
</script>

<style lang="scss"></style>
