<template>
  <div class="change-account-dialog">
    <w-dialog
      ref="dialogRef"
      title="切换账户"
      width="50%"
      btnWidth="140px"
      top="10vh"
      confirmText="确认"
      @wConfirm="handleSure"
    >
      <el-scrollbar max-height="200px" class="select-box">
        <el-tag
          v-for="item in selectData"
          :key="item"
          size="large"
          closable
          @close="removeSelected(item.id)"
          >{{ item.sn }} {{ item.tourist }}</el-tag
        >
      </el-scrollbar>
      <br />
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="AR账户" prop="new_ar_id">
              <el-select
                v-model="formData.new_ar_id"
                placeholder="请选择AR账户"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in wayOptions"
                  :key="item.id"
                  :disabled="item.id == oldArId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="selectData.length <= 1">
            <el-form-item label="游客名字" prop="ar_tourist">
              <el-input
                v-model="formData.ar_tourist"
                placeholder="请输入游客名字"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { ScenicApi } from "@/plugins/api.js";
export default {
  emits: ["submit", "changeSelect"],
  setup(props, { emit }) {
    const formData = ref({
      new_ar_id: "",
      ar_tourist: "",
    });
    const wayOptions = ref([]);
    onMounted(() => {
      ScenicApi.accountOptions().then((res) => {
        if (res.Code === 200) {
          wayOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取AR账户数据失败！";
          ElMessage.error(msg);
        }
      });
    });

    const dialogRef = ref(null);
    const formRules = reactive({
      new_ar_id: [
        {
          required: true,
          message: "请选择AR账户",
          trigger: "change",
        },
      ],
    });
    const selectData = ref([]);
    const oldArId = ref(null);
    function openDialog(data, id) {
      if (data.length <= 1) {
        formData.value = {
          new_ar_id: "",
          ar_tourist: data[0].tourist,
        };
      } else {
        formData.value = {
          new_ar_id: "",
          ar_tourist: "",
        };
      }
      selectData.value = data;
      oldArId.value = id;
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    function removeSelected(id) {
      const index = selectData.value.findIndex((item) => item.id == id);
      if (index != -1) {
        selectData.value.splice(index, 1);
        emit("changeSelect", selectData.value);
      }
      if (!selectData.value.length) {
        closeDialog();
      }
    }

    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            emit("submit", data);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      wayOptions,
      selectData,
      removeSelected,
      oldArId,
    };
  },
};
</script>

<style lang="scss">
.change-account-dialog {
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
    background-color: var(--search-uncheck-bg-color) !important;
    color: var(--text-gray-color);
  }
  .el-input-group__append {
    border-left: 1px solid #e6e3e3;
  }
  .el-input-group__prepend {
    border-right: 1px solid #e6e3e3;
  }
  .el-scrollbar__view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: "";
      width: 30%;
      height: 0px;
      visibility: hidden;
    }
    .el-tag {
      width: 30%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--theme-color);
      background-color: var(--search-bg-color);

      .el-tag__close {
        color: var(--theme-color);
        right: 0px;
        &:hover {
          background-color: var(--theme-color);
          color: #fff;
        }
      }
    }
  }
}
</style>
